/** React imports */
import React, {
  Context,
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
/** Goldspot imports */
import { AppContext } from '@/contexts/AppContext';
import {
  FileDirectoryResponse,
  SmartMatchFileListObject,
  SurveyAnomalyPair,
} from '@/typescript/interfaces';
import { DirectoryItem } from '@/typescript/types';
import { FileUploadStatus, SmartMatchFileType } from '@/typescript/enums';
import { getFileDirectory } from '@/utils/authenticated-requests/files';

export interface SmartMatchDataContextType {
  surveyFiles: SmartMatchFileListObject[];
  targetFiles: SmartMatchFileListObject[];
  surveyAnomalyPairs: SurveyAnomalyPair[];
  setSurveyAnomalyPairs: Dispatch<SetStateAction<SurveyAnomalyPair[]>>;
}

export const SmartMatchDataContext: Context<SmartMatchDataContextType> =
  createContext({} as SmartMatchDataContextType);

interface SmartMatchContextProviderProps {
  children: React.ReactNode;
}

export const SmartMatchContextProvider = ({
  children,
}: SmartMatchContextProviderProps) => {
  const { company, activeProject } = useContext(AppContext);
  const [surveyFiles, setSurveyFiles] = useState<SmartMatchFileListObject[]>(
    []
  );
  const [targetFiles, setTargetFiles] = useState<SmartMatchFileListObject[]>(
    []
  );
  const [surveyAnomalyPairs, setSurveyAnomalyPairs] = useState<
    SurveyAnomalyPair[]
  >([]);

  // Reset stateS when company or project changes
  useEffect(() => {
    setSurveyFiles([]);
    setTargetFiles([]);
    setSurveyAnomalyPairs([]);
  }, [company, activeProject]);

  // Fetch Survey Data files
  const {
    data,
    fetchNextPage: fetchNextSurveyFiles,
    hasNextPage: hasNextSurveyFiles,
    isFetchingNextPage: isFetchingNextSurveyFiles,
  } = useInfiniteQuery<FileDirectoryResponse>({
    queryKey: ['surveyFiles', company, activeProject.id],
    queryFn: ({ pageParam }) => {
      const surveysDataFilesPath = `/${company}/${activeProject.id}/files/surveys/`;
      return getFileDirectory(surveysDataFilesPath, {
        offset_token: pageParam,
      }).then((response: FileDirectoryResponse) => {
        return response;
      });
    },
    getNextPageParam: ({ next_offset_token }) => next_offset_token,
    onSuccess: (data) => {
      const surveysDirectoryItems: DirectoryItem[] = data.pages.flatMap(
        (page) => page.items
      );
      createAndGroupSmartMatchFiles(surveysDirectoryItems, 'surveys');
    },
    enabled: !!company && !!activeProject.id,
  });

  // Recursive Survey Data files fetching
  useEffect(() => {
    if (hasNextSurveyFiles && !isFetchingNextSurveyFiles) {
      fetchNextSurveyFiles();
    }
  }, [hasNextSurveyFiles, isFetchingNextSurveyFiles, fetchNextSurveyFiles]);

  // Fetch Target Anomaly files
  const {
    data: targetData,
    fetchNextPage: fetchNextTargetFiles,
    hasNextPage: hasNextTargetFiles,
    isFetchingNextPage: isFetchingNextTargetFiles,
  } = useInfiniteQuery<FileDirectoryResponse>({
    queryKey: ['targetFiles', company, activeProject.id],
    queryFn: ({ pageParam }) => {
      const targetsDataFilesPath = `/${company}/${activeProject.id}/files/targets/`;
      return getFileDirectory(targetsDataFilesPath, {
        offset_token: pageParam,
      }).then((response) => {
        return response;
      });
    },
    getNextPageParam: ({ next_offset_token }) => next_offset_token,
    onSuccess: (data) => {
      const targetsDirectoryItems: DirectoryItem[] = data.pages.flatMap(
        (page) => page.items
      );
      createAndGroupSmartMatchFiles(targetsDirectoryItems, 'targets');
    },
    enabled: !!company && !!activeProject.id,
  });

  // Recursive Target Anomaly files fetching
  useEffect(() => {
    if (hasNextTargetFiles && !isFetchingNextTargetFiles) {
      fetchNextTargetFiles();
    }
  }, [hasNextTargetFiles, isFetchingNextTargetFiles, fetchNextTargetFiles]);

  /**
   * Create and group the SmartMatch files.
   * @description
   * Each file in the directory is processed in which a new SmartMatch file is created and added to the appropriate file type group.
   * Creating a new SmartMatch file involves extracting the file name, size, status, type, destination folder, and complete path.
   * @param {DirectoryItem[]} files - The list of directory files to process
   * @param {string} folderName - The name of the folder containing the files
   */
  const createAndGroupSmartMatchFiles = (
    files: DirectoryItem[],
    folderName: string
  ): void => {
    const processFile = (
      file: DirectoryItem,
      type: SmartMatchFileType,
      setFiles: Dispatch<SetStateAction<SmartMatchFileListObject[]>>
    ): void => {
      // Reject an invalid file
      if (!('sizeBytes' in file)) return;

      const smartMatchFile: SmartMatchFileListObject = {
        name: getFileName(file.path),
        size: file.sizeBytes,
        status: FileUploadStatus.done,
        type: type,
        destinationFolder: `files/${folderName}`,
        completePath: file.path,
      };

      setFiles((prevFiles: SmartMatchFileListObject[]) => {
        if (
          prevFiles.some((f) => f.completePath === smartMatchFile.completePath)
        ) {
          return prevFiles;
        }
        return [...prevFiles, smartMatchFile];
      });
    };

    const fileTypeMap: {
      [key: string]: [
        SmartMatchFileType,
        Dispatch<SetStateAction<SmartMatchFileListObject[]>>
      ];
    } = {
      surveys: [SmartMatchFileType.SurveyData, setSurveyFiles],
      targets: [SmartMatchFileType.TargetAnomaly, setTargetFiles],
    };

    const fileTypeAndSetter = fileTypeMap[folderName];
    if (fileTypeAndSetter) {
      const [fileType, setFiles] = fileTypeAndSetter;
      files.forEach((file) => processFile(file, fileType, setFiles));
    }
  };

  /**
   * Get the file name from a given path
   * @param {string} path - The path to the file
   * @returns {string} The file name
   */
  const getFileName = (path: string): string => {
    const pathParts = path.split('/');
    return pathParts[pathParts.length - 1];
  };

  return (
    <SmartMatchDataContext.Provider
      value={{
        surveyFiles,
        targetFiles,
        surveyAnomalyPairs,
        setSurveyAnomalyPairs,
      }}
    >
      {children}
    </SmartMatchDataContext.Provider>
  );
};
