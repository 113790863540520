import en_general from '@/utils/localization/language/en-us/general.json';
import en_app from '@/utils/localization/language/en-us/app.json';
import en_projects from '@/utils/localization/language/en-us/projects.json';
import en_company from '@/utils/localization/language/en-us/company.json';
import en_files from '@/utils/localization/language/en-us/files.json';

// merge english sources
export const en = {
  translation: Object.assign(
    {},
    en_general,
    en_app,
    en_projects,
    en_company,
    en_files
  ),
};
