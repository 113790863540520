import { useContext } from 'react';
import { AppContext } from '@/contexts/AppContext';

export const useAppErrorAlert = () => {
  const { addAppAlert } = useContext(AppContext);

  /**
   * Handle error alerts in the application.
   * It extracts the error message from the error object, if available, otherwise it uses the default message.
   * The function then calls the `addAppAlert` function with an object containing the severity of the alert (always 'error' in this case),
   * the error message, and optionally the error details if available in the error object.
   * @param {any} error - error object from API response.
   * @param {string} defaultMessage - The default error message to use if no message is available in the error object.
   */
  const handleAppErrorAlerts = (error: any, defaultMessage: string) => {
    const errorResponseData: { error?: string; message?: string } =
      error?.error?.response?.data;
    const details = errorResponseData?.error ?? error?.error?.message;

    addAppAlert({
      severity: 'error',
      message: errorResponseData?.message ?? defaultMessage,
      ...(details && { details }),
    });
  };

  return handleAppErrorAlerts;
};

/**
 * Builds a query string from the given parameters object.
 *
 * @param {string[]} validKeys - A list of keys allowed in the query string
 * @param {Record<string, string | number | null>} params - The parameters object containing key-value pairs.
 * @returns {string} The query string constructed from the parameters object.
 */
export const parseQueryParams = (
  validKeys: string[],
  params?: Record<string, any>
): string => {
  if (!params) return '';

  const queryParams: string[] = [];

  validKeys.forEach((validKey: string) => {
    if (
      Object.prototype.hasOwnProperty.call(params, validKey) &&
      params[validKey] !== undefined &&
      params[validKey] !== ''
    ) {
      queryParams.push(`${validKey}=${params[validKey]}`);
    }
  });

  const queryString: string = queryParams.join('&');

  if (queryString.length > 0) {
    return '?' + queryString;
  } else {
    return queryString;
  }
};

export const validateFloatInput = (input: string): boolean => {
  const floatRegex = /^(?!0\d)\d+(\.\d{0,2}(0)?(?!0{1,}))?$/;
  return floatRegex.test(input);
};

/**
 * Format the timestamp into a human-readable string (e.g. 25 min 23.719 sec).
 * @param {string} timestamp - The timestamp to format
 * @returns {string} The formatted time string
 */
export const formatTime = (timestamp: string): string => {
  const [days, hours, minutes, seconds] = timestamp.split(':').map(Number);
  const date = new Date();
  date.setHours(hours, minutes, seconds);

  const parts = [];
  if (days > 0) {
    parts.push(`${days} day${days > 1 ? 's' : ''}`);
  }
  if (hours > 0) {
    parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} min`);
  }
  if (seconds > 0) {
    parts.push(`${seconds} sec`);
  }
  return parts.join(' ').trim();
};

/**
 * Get the file name from a given path
 * @param {string} path - The path to the file
 * @returns {string} The file name
 */
export const getFileName = (path: string): string => {
  const pathParts = path.split('/');
  return pathParts[pathParts.length - 1];
};

/**
 * Reformat a string to be used as a filename.
 * @description
 * All non-alphanumeric characters are replaced with an underscore.
 * @param {string} input - The input string.
 * @returns {string} - The formatted string.
 */
export const formatStringForFilename = (input: string): string => {
  const formattedString = input.replace(/[^a-zA-Z0-9]/g, '_');
  return formattedString;
};
