export enum ProjectType {
  Litholens = 'litholens',
  Tessellation = 'tessellation',
  SmartSuite = 'smartsuite',
  SmartMatch = 'smartmatch',
}

export enum SmartMatchRouteName {
  LoadData = 'load-data',
  SmartMatch = 'smartmatch',
}

export enum FileUploadStatus {
  notStarted = 'not started',
  pending = 'pending',
  inProgress = 'in progress',
  done = 'done',
  error = 'error',
  skip = 'skip',
}

export enum DupeHandling {
  version = 'version',
  overwrite = 'overwrite',
  rename = 'rename',
  skip = 'skip',
}

export enum SmartMatchFileType {
  SurveyData = 'Survey Data',
  TargetAnomaly = 'Target Anomaly',
}

export enum SmartMatchJobType {
  AnomalyMatching = 'smartmatch.anomaly_matching',
  AnomalyDetection = 'smartmatch.anomaly_detection',
}

export enum Axis {
  X = 'X',
  Y = 'Y',
}

export enum JobStatus {
  Done = 'done',
  InProgress = 'in-progress',
  Requested = 'requested',
  Todo = 'todo',
}

export enum JobResult {
  Successful = 'successful',
  Failed = 'failed',
}
