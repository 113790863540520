/** @mui/material imports */
import { Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
/** @mui/icons-material imports */
/** React and package imports */
import React, { useState, Context, createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  MutationCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
/** Goldspot imports */
import { AppContext } from '@/contexts/AppContext';
import { SmartMatchContextProvider } from '@/contexts/SmartMatchDataContext';
import {
  AlertObject,
  ProjectObject,
  SignedInUser,
  JobsResponse,
} from '@/typescript/interfaces';
import { getProject } from '@/utils/authenticated-requests/projects';
import { errorHandler } from '@/utils/error/error-handler';
import AppScopeLoadingComponent from '@/utils/components/AppScopeLoading';
import { AppAlertComponent } from '@/utils/components/AppAlert';

const AppRouter = React.lazy(() => {
  return import('./Router/router');
});

interface AppProps {
  user: SignedInUser;
  signOut: () => void;
}

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, { meta }) => {
      errorHandler(error as Error);
      // Show error message
      if (meta?.errorMessage) {
        console.error(meta?.errorMessage);
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      errorHandler(error as Error);
      // Show error message
      if (mutation.meta?.errorMessage) {
        console.error(mutation.meta?.errorMessage);
      }
    },
  }),
});

const App: React.FC<AppProps> = ({ user, signOut }): JSX.Element => {
  const [appLoading, setAppLoading] = useState<boolean>(false);
  const [activeProject, setActiveProject] = useState<ProjectObject>({});
  const [activeJob, setActiveJob] = useState<JobsResponse>({
    company: '',
    description: '',
    id: '',
    project: '',
  });
  const [appAlerts, setAppAlerts] = useState<AlertObject[] | []>([]);
  const [company, setCompany] = useState<string>(
    user.signInUserSession.idToken.payload['custom:company']
  ); //default company
  // High level state to control the Side Drawer via sibbling Navbar
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const addAppAlert = (alert: AlertObject): void => {
    setAppAlerts([...appAlerts, alert]);
  };

  const handleCloseAlert = (): void => {
    if (appAlerts.length > 0) {
      const tempAlerts = [...appAlerts];
      tempAlerts.shift(); // Removes the first Alert (closing one Aler at a time)
      setAppAlerts([...tempAlerts]);
    }
  };

  /**
   * Get project object and update context
   */
  const refreshProjectObject = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (activeProject?.id) {
        getProject(company, activeProject.id, true)
          .then((value: ProjectObject) => {
            setActiveProject(value);
            resolve();
          })
          .catch((err) => {
            errorHandler(err);
            reject(err);
          });
      } else {
        resolve();
      }
    });
  };

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider
          value={{
            user,
            appAlerts,
            setAppAlerts,
            addAppAlert,
            appLoading,
            setAppLoading,
            company,
            setCompany,
            activeProject,
            setActiveProject,
            refreshProjectObject,
            activeJob,
            setActiveJob,
            isDrawerOpen,
            setIsDrawerOpen,
          }}
        >
          <SmartMatchContextProvider>
            <Box sx={{ display: 'flex' }}>
              <React.Suspense fallback={<AppScopeLoadingComponent />}>
                <AppRouter user={user} signOut={signOut} />
              </React.Suspense>
            </Box>
            <Backdrop sx={{ color: '#fff', zIndex: '2000' }} open={appLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            {appAlerts.length > 0 && (
              <AppAlertComponent
                appAlert={appAlerts[0]}
                handleCloseAlert={handleCloseAlert}
              />
            )}
          </SmartMatchContextProvider>
        </AppContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
